/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
/*doc
---
title: Mixins
name: 02-mixins
category: Elements
---

### Bourbon & Neat

First off, be aware that we're using [Bourbon](http://bourbon.io/docs/) and [Neat](http://neat.bourbon.io/docs/) in Kickoff. Read up on their documentation to familiarize yourself with the mixins we get with those libraries.

### Respond()

This is our main responsive design mixin. It's built in mobile-first fashion and uses `em`s as the unit, and here's the usage:


```
.widget {

    // Default mobile-first styles here

    @include respond(20) {
        // Styles for screens from 20ems on up
    }

    @include respond(55) {
        // Styles for screens from 55rems on up
    }

    @include respond($b-showdesktop) {
        // Styles for screens from this variable on up
    }

}
```

There's an optional `ie` argument that you can pass in if you also want these styles to apply to IE8 since that browser doesn't like media queries.


```
.widget {

    // Default mobile-first styles here

    @include respond(20, ie) {
        // Styles for screens from 20ems on up and IE8
    }

}
```

Finally, the mixin applies ALL the styles to any page with a class of `desktop-only` on the `<body>`. This is to support older non-responsive and non-mobile-first pages.

### Font sizes

We've got a mixin for maintaining consistent font-sizes on a nice typographic scale. Any time you need to change the font size of an element, please use this mixin.
The possible values are (from smallest to largest): **small**, **p**, **5**, **4**, **3**, **2**, and **1**.

```
.widget {
    @include font-size(small) // .75rem
}

.widget {
    @include font-size(p) // default 1rem font size
}

.widget {
    @include font-size(5) // h5 size: 1.125rem
}

.widget {
    @include font-size(4) // h4 size: 1.3125rem
}

// etc

```

### Trade-heading()

A quick mixin for giving you that bold condensed trade heading that we all love. Pass in 'caps' to uppercase it, e.g., `trade-heading(caps)`

### Text-overflow()

For when you just don't have enough room. Hides overflow, doesn't wrap white-space and cuts off text with an ellipsis.

### CSS Spinner

There's a default `css-spinner` class that you can throw on any `<div>` but you'll likely want to customize it a bit.

```html_example
<div class="css-spinner"></div>
```

Here's how the mixin works if you want to put it on your own element and customize the colors.

```
.widget {

    // Arguments:
    // 1. border-width
    // 2. main color
    // 3. background color
    // 4. total width/height
    // 5. margin (optional. defaults to 0.)

    @include css-spinner(.5rem, $orange, $medium-gray, 4rem, 1rem);

}
```

*/
@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes spinner-rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 4rem;
  width: 4rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .css-spinner {
    display: none; }

.css-spinner-message {
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #8A8A8A; }
  .no-cssanimations .css-spinner-message {
    font-weight: bold;
    padding: 1.5rem;
    color: #FF6600;
    font-size: 20px; }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
@font-face {
  font-family: trade;
  font-style: normal;
  font-weight: normal;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/regular/regular.svg#trade") format("svg"); }

@font-face {
  font-family: trade;
  font-style: normal;
  font-weight: bold;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/bold/bold.svg#trade") format("svg"); }

@font-face {
  font-family: trade-condensed-heavy;
  font-style: normal;
  font-weight: bold;
  src: url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.eot?#iefix") format("embedded-opentype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.woff") format("woff"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.ttf") format("truetype"), url("https://hudl-fonts.s3.amazonaws.com/trade-gothic/condensed-heavy/condensed-heavy.svg#trade-condensed-heavy") format("svg"); }

.sharing-controls {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 30;
  display: none;
  opacity: 0;
  transition: opacity, .25s; }
  .sharing-controls.show {
    opacity: 1; }
  .sharing-controls svg {
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 5px; }
  .sharing-controls .close-icon {
    position: absolute;
    top: 20px;
    right: 26px;
    color: white;
    font-size: 31px;
    cursor: pointer;
    transition: color, 0.15s; }
    .sharing-controls .close-icon:hover {
      color: #8A8A8A; }
    @media screen and (max-width: 600px) {
      .sharing-controls .close-icon {
        font-size: 26px;
        top: 15px;
        right: 15px; } }
  .sharing-controls .sharing-info {
    margin-top: 12%; }
    @media screen and (max-width: 600px) {
      .sharing-controls .sharing-info {
        margin-top: 10%; } }
    .sharing-controls .sharing-info .sharing-title {
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-size: 78px;
      font-family: trade-condensed-heavy, sans-serif;
      font-weight: 700;
      cursor: default; }
      @media screen and (max-width: 600px) {
        .sharing-controls .sharing-info .sharing-title {
          font-size: 45px; } }
    .sharing-controls .sharing-info .sharing-link-container {
      background-color: white;
      box-sizing: border-box;
      padding: 15px 20px;
      margin: 0 auto;
      margin-top: 25px;
      width: 550px; }
      @media screen and (max-width: 600px) {
        .sharing-controls .sharing-info .sharing-link-container {
          margin-top: 10px;
          padding: 10px;
          width: 340px; } }
      .sharing-controls .sharing-info .sharing-link-container.hidden {
        display: none; }
      .sharing-controls .sharing-info .sharing-link-container .sharing-link {
        color: black;
        font-size: 22px;
        font-family: trade, sans-serif; }
        @media screen and (max-width: 600px) {
          .sharing-controls .sharing-info .sharing-link-container .sharing-link {
            font-size: 16px; } }
      .sharing-controls .sharing-info .sharing-link-container .sharing-copy {
        color: black;
        float: right;
        padding: 4px;
        font-family: trade, sans-serif;
        cursor: pointer;
        transition: background-color, 0.15s; }
        .sharing-controls .sharing-info .sharing-link-container .sharing-copy:hover {
          background-color: #F5F5F5; }
        @media screen and (max-width: 600px) {
          .sharing-controls .sharing-info .sharing-link-container .sharing-copy {
            margin-top: -4px; } }
    .sharing-controls .sharing-info .sharing-actions {
      text-align: center;
      margin-top: 15px; }
      .sharing-controls .sharing-info .sharing-actions .sharing-action {
        display: inline-block;
        padding: 10px;
        color: white;
        cursor: pointer;
        border-radius: 3px;
        transition: background-color, .15s;
        margin-right: 10px; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action .action-icon {
          font-size: 20px; }
          @media screen and (max-width: 600px) {
            .sharing-controls .sharing-info .sharing-actions .sharing-action .action-icon {
              font-size: 14px; } }
          .sharing-controls .sharing-info .sharing-actions .sharing-action .action-icon.icon-link {
            background-image: url("/Images/embeddable-player/icon-link.png");
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle; }
          .sharing-controls .sharing-info .sharing-actions .sharing-action .action-icon.icon-embed {
            background-image: url("/Images/embeddable-player/icon-embed.png");
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action .action-text {
          text-transform: uppercase;
          font-size: 16px;
          font-family: trade, sans-serif;
          vertical-align: middle;
          line-height: 1;
          position: relative;
          top: -3px; }
          @media screen and (max-width: 600px) {
            .sharing-controls .sharing-info .sharing-actions .sharing-action .action-text {
              font-size: 14px; } }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.twitter {
          background-color: #68b2d9; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.twitter:hover {
          background-color: #7cbcde; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.facebook {
          background-color: #1653ae; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.facebook:hover {
          background-color: #195ec5; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.email {
          background-color: #999999; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.email:hover {
          background-color: #a6a6a6; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.link, .sharing-controls .sharing-info .sharing-actions .sharing-action.embed {
          display: none;
          background-color: #cccccc; }
          @media only screen and (min-width: 25em) {
            .sharing-controls .sharing-info .sharing-actions .sharing-action.link, .sharing-controls .sharing-info .sharing-actions .sharing-action.embed {
              display: inline-block; } }
          .desktop-only .sharing-controls .sharing-info .sharing-actions .sharing-action.link, .desktop-only .sharing-controls .sharing-info .sharing-actions .sharing-action.embed {
            display: inline-block; }
          .sharing-controls .sharing-info .sharing-actions .sharing-action.link.hidden, .sharing-controls .sharing-info .sharing-actions .sharing-action.embed.hidden {
            display: none; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action.link:hover, .sharing-controls .sharing-info .sharing-actions .sharing-action.embed:hover {
          background-color: #d9d9d9; }
        .sharing-controls .sharing-info .sharing-actions .sharing-action:last-of-type {
          margin-right: 0; }
    .sharing-controls .sharing-info .sharing-embed-container {
      margin: 0 auto;
      margin-top: 25px;
      overflow-y: auto;
      width: 550px; }
      @media screen and (max-width: 600px) {
        .sharing-controls .sharing-info .sharing-embed-container {
          margin-top: 10px;
          width: 340px; } }
      .sharing-controls .sharing-info .sharing-embed-container textarea {
        font-size: 14px;
        width: 100%;
        padding: 10px;
        display: block;
        margin-bottom: 12px;
        border: 1px solid gainsboro;
        background: white;
        color: #171919;
        outline: none;
        transition: all 0.35s ease-in-out;
        -webkit-transition: all 0.35s ease-in-out;
        -moz-transition: all 0.35s ease-in-out;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px; }
      .sharing-controls .sharing-info .sharing-embed-container.hidden {
        display: none; }
      .sharing-controls .sharing-info .sharing-embed-container textarea[disabled] {
        opacity: 1; }

.no-opacity .sharing-controls {
  display: none; }
  .no-opacity .sharing-controls.show {
    display: block; }

.hudlplayer-cta-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity, .25s; }
  .hudlplayer-cta-container.show {
    opacity: 1; }

.hudlplayer-cta-inner {
  position: relative;
  width: 100%;
  height: 100%; }

.hudlplayer-cta-top {
  padding: 15px;
  transition: padding, .15s; }
  @media only screen and (min-width: 37.5em) {
    .hudlplayer-cta-top {
      padding: 25px; } }
  .desktop-only .hudlplayer-cta-top {
    padding: 25px; }

.hudlplayer-cta-top-picture {
  float: left;
  margin-right: 10px;
  transition: margin-right, .15s; }
  .hudlplayer-cta-top-picture img {
    display: none;
    height: 80px;
    width: 80px;
    transition: height, .15s; }
  @media only screen and (min-width: 18.75em) {
    .hudlplayer-cta-top-picture img {
      display: block; } }
  .desktop-only .hudlplayer-cta-top-picture img {
    display: block; }
  @media only screen and (min-width: 31.25em) {
    .hudlplayer-cta-top-picture img {
      width: 130px;
      height: 130px; } }
  .desktop-only .hudlplayer-cta-top-picture img {
    width: 130px;
    height: 130px; }
  @media only screen and (min-width: 37.5em) {
    .hudlplayer-cta-top-picture {
      margin-right: 15px; }
      .hudlplayer-cta-top-picture img {
        width: 150px;
        height: 150px; } }
  .desktop-only .hudlplayer-cta-top-picture {
    margin-right: 15px; }
    .desktop-only .hudlplayer-cta-top-picture img {
      width: 150px;
      height: 150px; }
  @media only screen and (min-width: 50em) {
    .hudlplayer-cta-top-picture {
      margin-right: 20px; }
      .hudlplayer-cta-top-picture img {
        width: 200px;
        height: 200px; } }
  .desktop-only .hudlplayer-cta-top-picture {
    margin-right: 20px; }
    .desktop-only .hudlplayer-cta-top-picture img {
      width: 200px;
      height: 200px; }

.hudlplayer-cta-top-info .title {
  color: white;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transition: all, .15sn; }

.hudlplayer-cta-top-info .position {
  font-size: 22px;
  line-height: 18px;
  transition: font-size, .15s; }

.hudlplayer-cta-top-info .name {
  font-size: 36px;
  line-height: 32px;
  text-transform: uppercase;
  transition: font-size, .15s; }

.hudlplayer-cta-top-info .school {
  font-size: 20px;
  line-height: 16px;
  font-weight: 400;
  color: #009ce3;
  transition: font-size, .15s; }

@media only screen and (min-width: 18.75em) {
  .hudlplayer-cta-top-info .name {
    margin-top: 7px; }
  .hudlplayer-cta-top-info .school {
    margin-top: 7px; } }

.desktop-only .hudlplayer-cta-top-info .name {
  margin-top: 7px; }

.desktop-only .hudlplayer-cta-top-info .school {
  margin-top: 7px; }

@media only screen and (min-width: 31.25em) {
  .hudlplayer-cta-top-info .name {
    font-size: 40px;
    line-height: 36px; } }

.desktop-only .hudlplayer-cta-top-info .name {
  font-size: 40px;
  line-height: 36px; }

@media only screen and (min-width: 37.5em) {
  .hudlplayer-cta-top-info .position {
    font-size: 28px;
    line-height: 24px; }
  .hudlplayer-cta-top-info .name {
    font-size: 52px;
    line-height: 48px; }
  .hudlplayer-cta-top-info .school {
    font-size: 22px;
    line-height: 18px; } }

.desktop-only .hudlplayer-cta-top-info .position {
  font-size: 28px;
  line-height: 24px; }

.desktop-only .hudlplayer-cta-top-info .name {
  font-size: 52px;
  line-height: 48px; }

.desktop-only .hudlplayer-cta-top-info .school {
  font-size: 22px;
  line-height: 18px; }

@media only screen and (min-width: 50em) {
  .hudlplayer-cta-top-info .position {
    font-size: 38px;
    line-height: 34px; }
  .hudlplayer-cta-top-info .name {
    font-size: 74px;
    line-height: 70px;
    margin-top: 5px; }
  .hudlplayer-cta-top-info .school {
    font-size: 26px;
    line-height: 22px;
    margin-top: 5px; } }

.desktop-only .hudlplayer-cta-top-info .position {
  font-size: 38px;
  line-height: 34px; }

.desktop-only .hudlplayer-cta-top-info .name {
  font-size: 74px;
  line-height: 70px;
  margin-top: 5px; }

.desktop-only .hudlplayer-cta-top-info .school {
  font-size: 26px;
  line-height: 22px;
  margin-top: 5px; }

.hudplayer-cta-buttons {
  margin-top: 16px;
  transition: font-size, .15s;
  transition: padding, .15s; }
  @media only screen and (min-width: 50em) {
    .hudplayer-cta-buttons {
      margin-top: 22px; }
      .hudplayer-cta-buttons .hudlplayer-cta-button {
        font-size: 16px;
        padding: 10px; }
      .hudplayer-cta-buttons .button-text {
        margin-left: 10px; } }
  .desktop-only .hudplayer-cta-buttons {
    margin-top: 22px; }
    .desktop-only .hudplayer-cta-buttons .hudlplayer-cta-button {
      font-size: 16px;
      padding: 10px; }
    .desktop-only .hudplayer-cta-buttons .button-text {
      margin-left: 10px; }

.hudlplayer-cta-button {
  display: inline-block;
  margin-right: 5px;
  padding: 4px 8px;
  color: white;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color, .15s; }
  .hudlplayer-cta-button.replay {
    background-color: #009ce3; }
    .hudlplayer-cta-button.replay:hover {
      background-color: #17b6ff; }
  .hudlplayer-cta-button.profile {
    background-color: #3e3e3e; }
    .hudlplayer-cta-button.profile:hover {
      background-color: #575757; }
  .hudlplayer-cta-button.share {
    background-color: #8A8A8A; }
    .hudlplayer-cta-button.share:hover {
      background-color: #a4a4a4; }
  .hudlplayer-cta-button:hover {
    color: white; }
  .hudlplayer-cta-button:nth-child(n+2) {
    display: none; }
  .hudlplayer-cta-button .button-text {
    margin-left: 5px; }
  @media only screen and (min-width: 18.75em) {
    .hudlplayer-cta-button {
      /*
        &:nth-child(1) {
            display: none;
        }
        */ }
      .hudlplayer-cta-button:nth-child(n+2) {
        display: inline-block; } }
  .desktop-only .hudlplayer-cta-button {
    /*
        &:nth-child(1) {
            display: none;
        }
        */ }
    .desktop-only .hudlplayer-cta-button:nth-child(n+2) {
      display: inline-block; }
  @media only screen and (min-width: 50em) {
    .hudlplayer-cta-button {
      padding: 8px; } }
  .desktop-only .hudlplayer-cta-button {
    padding: 8px; }

.related-athletes {
  box-sizing: border-box;
  list-style: none;
  padding: 20px;
  margin: 0;
  transition: padding, .15s; }
  @media only screen and (min-width: 37.5em) {
    .related-athletes {
      padding: 20px 25px; } }
  .desktop-only .related-athletes {
    padding: 20px 25px; }
  @media only screen and (min-width: 56.25em) {
    .related-athletes {
      padding: 35px 25px; } }
  .desktop-only .related-athletes {
    padding: 35px 25px; }

.hudl-jwplayer-preloader {
  height: 100%;
  width: 100%;
  top: -6px;
  left: 0;
  z-index: 20;
  position: absolute;
  overflow: hidden;
  background: black;
  opacity: 1; }
  .hudl-jwplayer-preloader .player-poster-image {
    display: table;
    margin: 0 auto;
    max-height: 100%;
    opacity: 1; }
  .hudl-jwplayer-preloader .fade-out-poster {
    transition: opacity, .25s;
    opacity: 0; }
  .hudl-jwplayer-preloader .hide-out-poster {
    display: none; }
  .hudl-jwplayer-preloader .buffering-spinner {
    -webkit-animation: spinner-rotation 0.75s infinite linear;
    -moz-animation: spinner-rotation 0.75s infinite linear;
    animation: spinner-rotation 0.75s infinite linear;
    clear: both;
    height: 32px;
    width: 32px;
    margin: 0 auto 0 auto;
    position: relative;
    border-top: 4px solid white;
    border-left: 4px solid rgba(255, 255, 255, 0.25);
    border-bottom: 4px solid rgba(255, 255, 255, 0.25);
    border-right: 4px solid rgba(255, 255, 255, 0.25);
    border-radius: 100%;
    display: block;
    display: inline-block; }
    .no-cssanimations .hudl-jwplayer-preloader .buffering-spinner {
      display: none; }
  .hudl-jwplayer-preloader .buffering-indicator {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s; }
    .hudl-jwplayer-preloader .buffering-indicator.active {
      opacity: 1; }
    .hudl-jwplayer-preloader .buffering-indicator .buffering-inner {
      border-radius: .125em;
      padding: .25em;
      display: flex;
      align-items: center;
      background-color: rgba(35, 42, 49, 0.15);
      background-image: -webkit-linear-gradient(rgba(35, 42, 49, 0.15), rgba(35, 42, 49, 0.25));
      background-image: linear-gradient(rgba(35, 42, 49, 0.15), rgba(35, 42, 49, 0.25)); }
      .no-flexbox .hudl-jwplayer-preloader .buffering-indicator .buffering-inner {
        display: inline-block;
        background: #333; }
    .hudl-jwplayer-preloader .buffering-indicator .buffering-spinner {
      -webkit-animation: spinner-rotation 0.75s infinite linear;
      -moz-animation: spinner-rotation 0.75s infinite linear;
      animation: spinner-rotation 0.75s infinite linear;
      clear: both;
      height: 32px;
      width: 32px;
      margin: 0 auto 0 auto;
      position: relative;
      border-top: 4px solid white;
      border-left: 4px solid rgba(255, 255, 255, 0.25);
      border-bottom: 4px solid rgba(255, 255, 255, 0.25);
      border-right: 4px solid rgba(255, 255, 255, 0.25);
      border-radius: 100%;
      display: block;
      margin-right: .25em;
      display: inline-block; }
      .no-cssanimations .hudl-jwplayer-preloader .buffering-indicator .buffering-spinner {
        display: none; }
    .hudl-jwplayer-preloader .buffering-indicator .buffering-label {
      font-size: 1.5em;
      display: inline-block;
      color: white;
      font-weight: bold; }
      .no-flexbox .hudl-jwplayer-preloader .buffering-indicator .buffering-label {
        display: block;
        margin-top: .5em; }

.html-loading-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 3rem;
  width: 3rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #8A8A8A;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block;
  margin-top: 25%; }
  .no-cssanimations .html-loading-spinner {
    display: none; }

.jw-svg-icon-sharing path {
  display: none; }

.jw-svg-icon-sharing {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EShare%3C%2Ftitle%3E%3Cpath%20d%3D%22M17.0000878%2C5.04652742%20L17.0000878%2C8.90504559%20C7.60103928%2C8.96585599%200%2C16.4975187%200%2C25.7779708%20L0%2C27.2658641%20C0.000771788938%2C27.6278413%200.269924362%2C27.9354716%200.634589591%2C27.9911737%20C0.999254819%2C28.0468759%201.35104121%2C27.8340933%201.46400756%2C27.4894894%20C4.11202125%2C21.6114774%2010.067052%2C17.5028519%2017.0000878%2C17.4577345%20L17.0000878%2C21.3731399%20C17.0000878%2C22.384358%2017.7660918%2C22.7845297%2018.7090967%2C22.2460634%20L31.2661616%2C14.2867663%20C32.2051664%2C13.7512424%2032.2511667%2C12.8175085%2031.357162%2C12.2054812%20L18.6190962%2C4.30699445%20C18.3170946%2C4.09906211%2018.0330932%2C4%2017.7900919%2C4%20C17.3180895%2C4%2017.0000878%2C4.37368974%2017.0000878%2C5.04652742%20Z%22%20id%3D%22share%22%20fill%3D%22%23FFF%22%20fill-opacity%3D%220.8%22%20fill-rule%3D%22evenodd%22%20%2F%3E%3C%2Fsvg%3E");
  background-size: contain;
  background-repeat: no-repeat; }
