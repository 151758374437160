/*doc
---
title: Variables
name: 01-variables
category: Elements
---

### Colors

#### Dark UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_dark_color">
        <div>$dui_dark_color</div>
        <div>#232A31</div>
    </div>
    <div class="ko-color-block-ui_dark_medium_color">
        <div>$ui_dark_medium_color</div>
        <div>#38434F</div>
    </div>
    <div class="ko-color-block-ui_dark_light_color">
        <div>$ui_dark_light_color</div>
        <div>#4E5D6C</div>
    </div>
</div>

#### Gray UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_gray_color">
        <div>$ui_gray_color</div>
        <div>#8A8A8A</div>
    </div>
    <div class="ko-color-block-ui_gray_medium_color">
        <div>$ui_gray_medium_color</div>
        <div>#E8E8E8</div>
    </div>
    <div class="ko-color-block-ui_gray_light_color">
        <div>$ui_gray_light_color</div>
        <div>#F5F5F5</div>
    </div>
</div>

#### Accent UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_blue_color">
        <div>$ui_blue_color</div>
        <div>#00AEEF</div>
    </div>
    <div class="ko-color-block-ui_orange_color">
        <div>$ui_orange_color</div>
        <div>#FF6600</div>
    </div>
</div>

#### Alert UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_red_color">
        <div>$ui_red_color</div>
        <div>#D81D1D</div>
    </div>
    <div class="ko-color-block-ui_yellow_color">
        <div>$ui_yellow_color</div>
        <div>#FABE39</div>
    </div>
    <div class="ko-color-block-ui_green_color">
        <div>$ui_green_color</div>
        <div>#6AA220</div>
    </div>
</div>

#### Grayscale UI Text Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_dark_color">
        <div>$ui_text_dark_color</div>
        <div>#333333</div>
    </div>
    <div class="ko-color-block-ui_text_medium_color">
        <div>$ui_text_medium_color</div>
        <div>#666666</div>
    </div>
    <div class="ko-color-block-ui_text_light_color">
        <div>$ui_text_light_color</div>
        <div>#999999</div>
    </div>
</div>
<div class="ko-color-blocks">
    <div class="ko-color-block-ui_text_extra_light_color">
        <div>$ui_text_extra_light_color</div>
        <div>#CCCCCC</div>
    </div>
</div>

#### Base UI Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-ui_black_color">
        <div>$ui_black_color</div>
        <div>#FFFFFF</div>
    </div>
    <div class="ko-color-block-ui_white_color">
        <div>$ui_white_color</div>
        <div>#000000</div>
    </div>
</div>

#### Team Colors

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_white_color">
        <div>$tagging_team_white_color</div>
        <div>#E9E9E9</div>
    </div>
    <div class="ko-color-block-tagging_team_gray_color">
        <div>$tagging_team_gray_color</div>
        <div>#8C9196</div>
    </div>
    <div class="ko-color-block-tagging_team_black_color">
        <div>$tagging_team_black_color</div>
        <div>#404040</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_yellow_color">
        <div>$tagging_team_yellow_color</div>
        <div>#FFDD30</div>
    </div>
    <div class="ko-color-block-tagging_team_orange_color">
        <div>$tagging_team_orange_color</div>
        <div>#ED9827</div>
    </div>
    <div class="ko-color-block-tagging_team_red_color">
        <div>$tagging_team_red_color</div>
        <div>#DF4953</div>
    </div>
</div>

<div class="ko-color-blocks">
    <div class="ko-color-block-tagging_team_green_color">
        <div>$tagging_team_green_color</div>
        <div>#42BE39</div>
    </div>
    <div class="ko-color-block-tagging_team_blue_color">
        <div>$tagging_team_blue_color</div>
        <div>#3985BE</div>
    </div>
    <div class="ko-color-block-tagging_team_purple_color">
        <div>$tagging_team_purple_color</div>
        <div>#9F62B1</div>
    </div>
</div>

### Font Sizes

These should really be used by the `font-size()` mixin to maintain consistent typography. The possible values are (from smallest to largest): **small**, **p**, and **5** to **1** (or **h5** to **h1** if that's your jam).

### Z-index

Try to stick to the variables defined here for z-index values, e.g., `z-index: $z-1` instead of arbitrary numbers. The possible values are: **z-1** (100) to **z-7** (700).

### Transition Lengths

Again, try to stick to these instead of arbitrary values. The possible values are: **t-quick** (0.2s), **t-med** (0.3s), and **t-slow** (0.5s).

### Common Breakpoints

Put breakpoints that you end up referencing a lot here and give it a prefix of `b-`, e.g., `b-showdesktop`. These are usually referenced by the `respond()` mixin.

*/
/*doc
---
title: Mixins
name: 02-mixins
category: Elements
---

### Bourbon & Neat

First off, be aware that we're using [Bourbon](http://bourbon.io/docs/) and [Neat](http://neat.bourbon.io/docs/) in Kickoff. Read up on their documentation to familiarize yourself with the mixins we get with those libraries.

### Respond()

This is our main responsive design mixin. It's built in mobile-first fashion and uses `em`s as the unit, and here's the usage:


```
.widget {

    // Default mobile-first styles here

    @include respond(20) {
        // Styles for screens from 20ems on up
    }

    @include respond(55) {
        // Styles for screens from 55rems on up
    }

    @include respond($b-showdesktop) {
        // Styles for screens from this variable on up
    }

}
```

There's an optional `ie` argument that you can pass in if you also want these styles to apply to IE8 since that browser doesn't like media queries.


```
.widget {

    // Default mobile-first styles here

    @include respond(20, ie) {
        // Styles for screens from 20ems on up and IE8
    }

}
```

Finally, the mixin applies ALL the styles to any page with a class of `desktop-only` on the `<body>`. This is to support older non-responsive and non-mobile-first pages.

### Font sizes

We've got a mixin for maintaining consistent font-sizes on a nice typographic scale. Any time you need to change the font size of an element, please use this mixin.
The possible values are (from smallest to largest): **small**, **p**, **5**, **4**, **3**, **2**, and **1**.

```
.widget {
    @include font-size(small) // .75rem
}

.widget {
    @include font-size(p) // default 1rem font size
}

.widget {
    @include font-size(5) // h5 size: 1.125rem
}

.widget {
    @include font-size(4) // h4 size: 1.3125rem
}

// etc

```

### Trade-heading()

A quick mixin for giving you that bold condensed trade heading that we all love. Pass in 'caps' to uppercase it, e.g., `trade-heading(caps)`

### Text-overflow()

For when you just don't have enough room. Hides overflow, doesn't wrap white-space and cuts off text with an ellipsis.

### CSS Spinner

There's a default `css-spinner` class that you can throw on any `<div>` but you'll likely want to customize it a bit.

```html_example
<div class="css-spinner"></div>
```

Here's how the mixin works if you want to put it on your own element and customize the colors.

```
.widget {

    // Arguments:
    // 1. border-width
    // 2. main color
    // 3. background color
    // 4. total width/height
    // 5. margin (optional. defaults to 0.)

    @include css-spinner(.5rem, $orange, $medium-gray, 4rem, 1rem);

}
```

*/
@-webkit-keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@-moz-keyframes spinner-rotation {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(359deg); } }

@keyframes spinner-rotation {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

.css-spinner {
  -webkit-animation: spinner-rotation 0.75s infinite linear;
  -moz-animation: spinner-rotation 0.75s infinite linear;
  animation: spinner-rotation 0.75s infinite linear;
  clear: both;
  height: 4rem;
  width: 4rem;
  margin: 1rem auto 1rem auto;
  position: relative;
  border-top: 0.5rem solid #FF6600;
  border-left: 0.5rem solid #E8E8E8;
  border-bottom: 0.5rem solid #E8E8E8;
  border-right: 0.5rem solid #E8E8E8;
  border-radius: 100%;
  display: block; }
  .no-cssanimations .css-spinner {
    display: none; }

.css-spinner-message {
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  color: #8A8A8A; }
  .no-cssanimations .css-spinner-message {
    font-weight: bold;
    padding: 1.5rem;
    color: #FF6600;
    font-size: 20px; }

/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
.hudlplayer-cta-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  z-index: 20; }
  @media only screen and (min-width: 41em) {
    .hudlplayer-cta-container {
      height: calc(100% - 45px); } }
  .desktop-only .hudlplayer-cta-container {
    height: calc(100% - 45px); }

.hudlplayer-cta-replay {
  height: 100%;
  width: 100%;
  color: white;
  position: relative;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  .hudlplayer-cta-replay.hidden {
    display: none; }
  .hudlplayer-cta-replay .hudlplayer-cta-play-container {
    height: 100px;
    width: 100px;
    box-sizing: content-box;
    cursor: pointer;
    margin: 20% auto 2% auto; }
    @media only screen and (min-width: 100em) {
      .hudlplayer-cta-replay .hudlplayer-cta-play-container {
        height: 200px;
        width: 200px; } }
    .desktop-only .hudlplayer-cta-replay .hudlplayer-cta-play-container {
      height: 200px;
      width: 200px; }
  .hudlplayer-cta-replay .player-cta {
    display: block;
    height: 30px;
    width: 30px;
    position: relative;
    top: calc(50% - 15px);
    left: calc(50% - 10px);
    fill: #FFFFFF; }
    @media only screen and (min-width: 100em) {
      .hudlplayer-cta-replay .player-cta {
        height: 60px;
        width: 60px;
        top: calc(50% - 30px);
        left: calc(50% - 24px); } }
    .desktop-only .hudlplayer-cta-replay .player-cta {
      height: 60px;
      width: 60px;
      top: calc(50% - 30px);
      left: calc(50% - 24px); }

.hudlplayer-cta-play-container {
  box-sizing: content-box;
  cursor: pointer;
  margin: 0 auto;
  height: 50px;
  width: 50px; }
  @media only screen and (min-width: 17em) {
    .hudlplayer-cta-play-container {
      height: 68px;
      width: 68px; } }
  .desktop-only .hudlplayer-cta-play-container {
    height: 68px;
    width: 68px; }
  @media only screen and (min-width: 30em) {
    .hudlplayer-cta-play-container {
      height: 81px;
      width: 81px; } }
  .desktop-only .hudlplayer-cta-play-container {
    height: 81px;
    width: 81px; }

.progress-container > svg {
  width: 50px;
  height: 50px; }
  @media only screen and (min-width: 17em) {
    .progress-container > svg {
      width: 68px;
      height: 68px; } }
  .desktop-only .progress-container > svg {
    width: 68px;
    height: 68px; }
  @media only screen and (min-width: 30em) {
    .progress-container > svg {
      width: 81px;
      height: 81px; } }
  .desktop-only .progress-container > svg {
    width: 81px;
    height: 81px; }

.progress-container.hidden {
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
  opacity: 0; }

.player-cta {
  display: block;
  position: relative;
  fill: #FFFFFF;
  height: 16px;
  width: 16px;
  top: calc(50% - 8px);
  left: calc(50% - 5px); }
  @media only screen and (min-width: 17em) {
    .player-cta {
      height: 20px;
      width: 20px;
      top: calc(50% - 10px);
      left: calc(50% - 7px); } }
  .desktop-only .player-cta {
    height: 20px;
    width: 20px;
    top: calc(50% - 10px);
    left: calc(50% - 7px); }
  @media only screen and (min-width: 30em) {
    .player-cta {
      height: 30px;
      width: 30px;
      top: calc(50% - 15px);
      left: calc(50% - 10px); } }
  .desktop-only .player-cta {
    height: 30px;
    width: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 10px); }

.hudlplayer-cta-play-outer {
  border-radius: 50px;
  height: 100%;
  width: 100%;
  margin: auto;
  background-color: rgba(255, 102, 0, 0.3); }

.hudlplayer-cta-play-inner {
  border-radius: 50px;
  margin: auto;
  background-color: rgba(255, 102, 0, 0.8);
  position: relative;
  height: 44px;
  width: 44px;
  top: -47px; }
  @media only screen and (min-width: 17em) {
    .hudlplayer-cta-play-inner {
      height: 64px;
      width: 64px;
      top: -66px; } }
  .desktop-only .hudlplayer-cta-play-inner {
    height: 64px;
    width: 64px;
    top: -66px; }
  @media only screen and (min-width: 30em) {
    .hudlplayer-cta-play-inner {
      height: 77px;
      width: 77px;
      top: -79px; } }
  .desktop-only .hudlplayer-cta-play-inner {
    height: 77px;
    width: 77px;
    top: -79px; }

.hudlplayer-cta-time {
  font-size: 11px;
  height: 10px;
  line-height: 10px;
  font-family: trade; }
  @media only screen and (min-width: 17em) {
    .hudlplayer-cta-time {
      display: none; } }
  .desktop-only .hudlplayer-cta-time {
    display: none; }
  @media only screen and (min-width: 41em) {
    .hudlplayer-cta-time {
      display: block;
      font-size: 12px;
      margin-top: 19px; } }
  .desktop-only .hudlplayer-cta-time {
    display: block;
    font-size: 12px;
    margin-top: 19px; }
  @media only screen and (min-width: 100em) {
    .hudlplayer-cta-time {
      display: block;
      height: 24px;
      line-height: 24px;
      font-size: 20px; } }
  .desktop-only .hudlplayer-cta-time {
    display: block;
    height: 24px;
    line-height: 24px;
    font-size: 20px; }
  .hudlplayer-cta-time.hidden {
    visibility: hidden; }

.hudlplayer-cta-title {
  text-transform: uppercase;
  font-weight: bold;
  font-family: trade;
  height: 15px;
  line-height: 15px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media only screen and (min-width: 17em) {
    .hudlplayer-cta-title {
      margin-top: 5px; } }
  .desktop-only .hudlplayer-cta-title {
    margin-top: 5px; }
  @media only screen and (min-width: 30em) {
    .hudlplayer-cta-title {
      font-size: 22px;
      height: 26px;
      line-height: 26px; } }
  .desktop-only .hudlplayer-cta-title {
    font-size: 22px;
    height: 26px;
    line-height: 26px; }
  @media only screen and (min-width: 41em) {
    .hudlplayer-cta-title {
      margin-top: 30px;
      font-size: 28px; } }
  .desktop-only .hudlplayer-cta-title {
    margin-top: 30px;
    font-size: 28px; }
  @media only screen and (min-width: 100em) {
    .hudlplayer-cta-title {
      font-size: 32px;
      height: 36px;
      line-height: 36px; } }
  .desktop-only .hudlplayer-cta-title {
    font-size: 32px;
    height: 36px;
    line-height: 36px; }

.hudlplayer-cta-related {
  height: 100%;
  width: 100%; }
  .hudlplayer-cta-related.hidden {
    display: none; }

.hudlplayer-cta-cancel-container {
  margin-top: 20px; }
  @media only screen and (min-width: 17em) {
    .hudlplayer-cta-cancel-container {
      margin-top: 7px; } }
  .desktop-only .hudlplayer-cta-cancel-container {
    margin-top: 7px; }
  @media only screen and (min-width: 30em) {
    .hudlplayer-cta-cancel-container {
      margin-top: 20px; } }
  .desktop-only .hudlplayer-cta-cancel-container {
    margin-top: 20px; }
  @media only screen and (min-width: 50em) {
    .hudlplayer-cta-cancel-container {
      margin-top: 32px; } }
  .desktop-only .hudlplayer-cta-cancel-container {
    margin-top: 32px; }
  @media only screen and (min-width: 100em) {
    .hudlplayer-cta-cancel-container {
      margin-top: 58px; } }
  .desktop-only .hudlplayer-cta-cancel-container {
    margin-top: 58px; }
  .hudlplayer-cta-cancel-container.hidden {
    visibility: hidden; }

.hudlplayer-cta-cancel-btn {
  font-family: trade;
  text-decoration: underline;
  color: #FFFFFF;
  cursor: pointer;
  background: none;
  border: none;
  padding: 2px;
  font-size: 10px;
  height: 10px; }
  @media only screen and (min-width: 17em) {
    .hudlplayer-cta-cancel-btn {
      font-size: 12px;
      height: 26px; } }
  .desktop-only .hudlplayer-cta-cancel-btn {
    font-size: 12px;
    height: 26px; }
  @media only screen and (min-width: 30em) {
    .hudlplayer-cta-cancel-btn {
      padding: 5px; } }
  .desktop-only .hudlplayer-cta-cancel-btn {
    padding: 5px; }
  @media only screen and (min-width: 100em) {
    .hudlplayer-cta-cancel-btn {
      font-size: 20px;
      height: 30px; } }
  .desktop-only .hudlplayer-cta-cancel-btn {
    font-size: 20px;
    height: 30px; }
  .no-touch .hudlplayer-cta-cancel-btn:hover {
    background-color: rgba(35, 42, 49, 0.75); }

.related-videos {
  height: 100%;
  box-sizing: border-box;
  list-style: none;
  overflow: hidden; }

.related-video {
  float: left;
  width: 100%;
  height: 33%;
  background-repeat: no-repeat;
  background-size: cover; }
  .related-video:first-child {
    height: 100%;
    width: 100%; }
    @media only screen and (min-width: 41em) {
      .related-video:first-child {
        width: 78%; } }
    .desktop-only .related-video:first-child {
      width: 78%; }
    .related-video:first-child .link {
      display: none; }
  @media only screen and (min-width: 41em) {
    .related-video:only-child {
      width: 100%; } }
  .desktop-only .related-video:only-child {
    width: 100%; }
  .related-video:nth-child(n+2) {
    float: right;
    display: none; }
    @media only screen and (min-width: 41em) {
      .related-video:nth-child(n+2) {
        display: block;
        width: 22%; } }
    .desktop-only .related-video:nth-child(n+2) {
      display: block;
      width: 22%; }
    .related-video:nth-child(n+2) .video-cta-information {
      display: none; }
  .related-video:nth-child(4) {
    height: 34%; }
  .related-video .link {
    display: block;
    height: 100%;
    width: 100%; }
  .related-video .video-cta-information {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
    font-size: 11px;
    font: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
    text-align: center;
    position: relative; }
  .related-video .cta-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%; }

@media only screen and (min-width: 17em) {
  .hudlplayer-cta-athlete-info {
    display: none; } }

.desktop-only .hudlplayer-cta-athlete-info {
  display: none; }

@media only screen and (min-width: 41em) {
  .hudlplayer-cta-athlete-info {
    display: block; } }

.desktop-only .hudlplayer-cta-athlete-info {
  display: block; }

@media only screen and (min-width: 100em) {
  .hudlplayer-cta-athlete-info {
    display: block; } }

.desktop-only .hudlplayer-cta-athlete-info {
  display: block; }

.hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-name-position {
  font-family: trade;
  font-size: 12px;
  height: 12px;
  line-height: 12px; }
  @media only screen and (min-width: 41em) {
    .hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-name-position {
      font-size: 14px;
      height: 19px;
      line-height: 19px;
      margin-top: 4px; } }
  .desktop-only .hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-name-position {
    font-size: 14px;
    height: 19px;
    line-height: 19px;
    margin-top: 4px; }
  @media only screen and (min-width: 100em) {
    .hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-name-position {
      font-size: 18px; } }
  .desktop-only .hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-name-position {
    font-size: 18px; }

.hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-grad-year {
  display: none;
  height: 12px;
  line-height: 12px;
  font-family: trade;
  font-size: 11px; }
  @media only screen and (min-width: 34em) {
    .hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-grad-year {
      display: inline-block;
      margin-top: 4px; } }
  .desktop-only .hudlplayer-cta-athlete-info .hudlplayer-cta-athlete-grad-year {
    display: inline-block;
    margin-top: 4px; }

.suggestion-play-icon {
  display: block;
  position: relative;
  top: calc(50% - 15px);
  left: 45%;
  width: 24px;
  height: 30px;
  fill: #FFFFFF; }

.hudlplayer-cta-label {
  font-size: 12px;
  margin-top: 6px; }
  @media only screen and (min-width: 17em) {
    .hudlplayer-cta-label {
      font-size: 11px;
      margin-top: 5px; } }
  .desktop-only .hudlplayer-cta-label {
    font-size: 11px;
    margin-top: 5px; }
  @media only screen and (min-width: 41em) {
    .hudlplayer-cta-label {
      font-size: 12px;
      margin-top: 6px; } }
  .desktop-only .hudlplayer-cta-label {
    font-size: 12px;
    margin-top: 6px; }
  @media only screen and (min-width: 100em) {
    .hudlplayer-cta-label {
      font-size: 20px; } }
  .desktop-only .hudlplayer-cta-label {
    font-size: 20px; }
